import { createStore } from "vuex";



export default createStore({
  state: {
    TOKEN: null,
    locale: "ar",
  },
  getters: {
  },
  mutations: {
    updateCartFromLocalStorage(state) {
      if(localStorage.getItem('locale') == null){
        localStorage.setItem('locale','ar')
      }
    
      const TOKEN = localStorage.getItem("_token");
      state.TOKEN = TOKEN;

    },

    changeLocale(state, locale) {
      state.locale = locale;
      localStorage.setItem("locale", locale);
    },
    logOut(state) {
      state.TOKEN = null;
      localStorage.removeItem("_token");
    },
  },

  actions: {},
  modules: {},
});
