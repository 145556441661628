import { createApp } from 'vue'
import App from './App.vue'
import router from './router'


//Fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas, fab)

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

//locale
import en from "./locale/en";
import ar from "./locale/ar";

const vuetify = createVuetify({
  components,
  directives,
  locale: {
    locale: "ar",
    fallback: "ar",
    messages: { en, ar },
  },
})

//Bootstrap
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import './assets/styles/main.css'

//State Mangment
import store from './store'



createApp(App)
  .use(router)
  .use(vuetify)
  .use(store)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app')
