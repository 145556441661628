<template>
  <v-app class="app-content">
    <router-view />
  </v-app>
</template>

<script>
export default {
  mounted() {
    this.$vuetify.locale.current = localStorage.getItem("locale");
  },
};
</script>
